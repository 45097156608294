import Dialog from "@mui/material/Dialog";
import qrcode from '../assets/qrcode.png';

const QrDialog = ({ open, handleClose }) => {
    return <Dialog open={open} onClose={handleClose} maxWidth={'xs'} fullWidth style={{maxWidth: '40%', margin: 'auto'}}>
        <img src={qrcode} alt={'QR Code'}/>
    </Dialog>
}

export default QrDialog;
